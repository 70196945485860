export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

export default class extends Component {
    static template = template;
    static defaults() {
        return {
            sizes: ['large', 'default', 'small', 'mini'] as const
        };
    }
}