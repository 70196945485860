import {
    createElementVNode as _$ce,
    map as _$ma,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ma(this.get('sizes'), function($value, $key) {
            return _$cc(Tip, {
                'type': 'primary',
                'size': $value,
                'children': $value
            });
        }, $this),
        _$ce(2, 'h3', '带关闭按钮', 16 /* HasTextChildren */),
        _$ma(this.get('sizes'), function($value, $key) {
            return _$cc(Tip, {
                'type': 'primary',
                'size': $value,
                'closable': true,
                'children': $value
            });
        }, $this)
    ], 0 /* UnknownChildren */);
};