import {
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    noop as _$no,
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createElementVNode as _$ce,
} from 'intact';
import {Button} from '../button';
import {Icon} from '../icon';
import {makeStyles} from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {children, closable, type, disabled, showIcon} = this.get();
    const { k } = this.config;
    
    const iconMap = {
        default: 'information-fill',
        info: 'information-fill',
        warning: 'warning-fill',
        success: 'success-fill',
        error: 'error-fill',
    };
    
    const classNameObj = {
        [`${k}-tip`]: true,
        [makeStyles(k)]: true,
    };
    
    const iconWrapper = (
        (!$blocks.icon) ?
            (
                _$cc(Icon, {
                    'className': _$cn({
                        [`${k}-icon-${iconMap[type] || iconMap.info}`]: true,
                        [`${k}-tip-icon`]: true,
                    })
                })
            ) :
            (
                (_$blocks['icon'] = function($super) {
                    return null;
                }),
                (__$blocks['icon'] = function($super, data) {
                    var block = $blocks['icon'];
                    var callBlock = function() {
                        return _$blocks['icon'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                }),
                __$blocks['icon'](_$no)
            )
    )
    
    return _$su.call($this, {
        'className': _$cn(classNameObj),
        'name': 'k-fade'
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['body'] = function($super) {
                    return (
                        _$ce(2, 'div', [
                            (!$blocks.title && (showIcon || $blocks.icon)) ?
                                iconWrapper :
                                undefined,
                            ($blocks.title) ?
                                _$ce(2, 'div', [
                                    (showIcon) ?
                                        iconWrapper :
                                        undefined,
                                    _$ce(2, 'span', (
                                        (
                                            (_$blocks['title'] = function($super) {
                                                return null;
                                            }),
                                            (__$blocks['title'] = function($super, data) {
                                                var block = $blocks['title'];
                                                var callBlock = function() {
                                                    return _$blocks['title'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            }),
                                            __$blocks['title'](_$no)
                                        )
                                    ), 0 /* UnknownChildren */, _$cn(`${k}-title-content`))
                                ], 0 /* UnknownChildren */, _$cn({
                                    [`${k}-tip-title`]: true,
                                    [`${k}-with-icon`]: showIcon
                                })) :
                                undefined,
                            _$ce(2, 'div', children, 0 /* UnknownChildren */, _$cn(`${k}-tip-content`))
                        ], 0 /* UnknownChildren */, _$cn({
                            [`${k}-tip-wrapper`]: true,
                            [`${k}-with-icon`]: !$blocks.title && (showIcon || $blocks.icon)
                        }))
                    );
                }),
                (__$blocks['body'] = function($super, data) {
                    var block = $blocks['body'];
                    var callBlock = function() {
                        return _$blocks['body'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            (
                (_$blocks['_close'] = function($super) {
                    return (
                        (closable) ?
                            _$cc(Button, {
                                'type': !$blocks.close ? 'none' : 'link',
                                'icon': !$blocks.close,
                                'ev-click': this.onClose,
                                'className': _$cn(`${k}-tip-close`),
                                'children': (
                                    (
                                        (
                                            (_$blocks['close'] = function($super) {
                                                return (
                                                    _$cc(Icon, {
                                                        'className': _$cn(`${k}-icon-close`),
                                                        'hoverable': !disabled
                                                    })
                                                );
                                            }),
                                            (__$blocks['close'] = function($super, data) {
                                                var block = $blocks['close'];
                                                var callBlock = function() {
                                                    return _$blocks['close'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            }),
                                            __$blocks['close'](_$no)
                                        )
                                    )
                                )
                            }) :
                            undefined
                    );
                }),
                (__$blocks['_close'] = function($super, data) {
                    var block = $blocks['_close'];
                    var callBlock = function() {
                        return _$blocks['_close'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};