import {
    createElementVNode as _$ce,
    createTextVNode as _$ct,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    noop as _$no,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    map as _$ma,
    createVNode as _$cv,
} from 'intact';
import {linkEvent, Transition, TransitionGroup} from 'intact';
import {getRestProps} from '../utils';
import {makeStyles} from './styles';
import {Input} from '../input';
import {Checkbox} from '../checkbox';
import {Button} from '../button';
import {Tree} from '../tree';
import {Icon} from '../icon';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        data, className, keyName, labelName, 
        filterable, filter, placeholder 
    } = this.get();
    const {getShowedData} = this.filter;
    const {enableAdd, enableRemove, add, remove} = this.transfer;
    const {isCheckAll, toggleCheckAll, onCheckboxChange, isIndeterminate} = this.check;
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-transfer`]: true,
        [className]: className,
        [makeStyles(k)]: true,
    };
    
    const Panel = (model) => {
        const title = this.get(`${model}Title`);
        const data = getShowedData(model);
    
        return _$ce(2, 'div', [
            _$ce(2, 'div', (
                (
                    (_$blocks['header'] = function($super) {
                        return [
                            _$ce(2, 'div', [
                                this.get(`${model}CheckedKeys.length`),
                                _$ct(' /\n                    '),
                                data.length
                            ], 0 /* UnknownChildren */, _$cn(`${k}-transfer-count`)),
                            _$cc(Checkbox, {
                                'className': 'c-ellipsis',
                                'value': isCheckAll(model),
                                'indeterminate': isIndeterminate(model),
                                'ev-click': toggleCheckAll.bind(null, model),
                                'children': title
                            })
                        ];
                    }),
                    (__$blocks['header'] = function($super, data) {
                        var block = $blocks['header'];
                        var callBlock = function() {
                            return _$blocks['header'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['header'](_$no, model)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-transfer-title`)),
            (filterable) ?
                (
                    (_$blocks['filter'] = function($super) {
                        return (
                            _$cc(Input, {
                                'placeholder': placeholder,
                                'clearable': true,
                                'size': 'small',
                                '$blocks': function($blocks) {
                                    var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                    return (
                                        (
                                            (_$blocks['prefix'] = function($super) {
                                                return (
                                                    _$cc(Icon, {
                                                        'className': _$cn(`${k}-icon-search`),
                                                        'size': 'small'
                                                    })
                                                );
                                            }),
                                            (__$blocks['prefix'] = function($super, data) {
                                                var block = $blocks['prefix'];
                                                var callBlock = function() {
                                                    return _$blocks['prefix'].call($this, $super, data);
                                                };
                                                return block ?
                                                    block.call($this, callBlock, data) :
                                                    callBlock();
                                            })
                                        ),
                                        __$blocks
                                    );
                                }.call($this, _$em),
                                'ev-$model:value': function($v) {
                                    $this.set(`${model}Keywords`, $v);
                                },
                                'value': $this.get(`${model}Keywords`)
                            })
                        );
                    }),
                    (__$blocks['filter'] = function($super, data) {
                        var block = $blocks['filter'];
                        var callBlock = function() {
                            return _$blocks['filter'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['filter'](_$no, model)
                ) :
                undefined,
            _$ce(2, 'div', (
                (
                    (_$blocks['list'] = function($super) {
                        return (
                            _$cc(TransitionGroup, {
                                'name': 'k-fade',
                                'children': (
                                    _$ma(data, function($value, $key) {
                                        return _$ce(2, 'div', (
                                            _$cc(Checkbox, {
                                                'name': model,
                                                'trueValue': $value[keyName],
                                                'disabled': $value.disabled,
                                                'ev-click': onCheckboxChange.bind(this, model, $key),
                                                'children': (
                                                    (
                                                        (
                                                            (_$blocks['label'] = function($super) {
                                                                return $value[labelName];
                                                            }),
                                                            (__$blocks['label'] = function($super, data) {
                                                                var block = $blocks['label'];
                                                                var callBlock = function() {
                                                                    return _$blocks['label'].call($this, $super, data);
                                                                };
                                                                return block ?
                                                                    block.call($this, callBlock, data) :
                                                                    callBlock();
                                                            }),
                                                            __$blocks['label'](_$no, [$value, $key, model])
                                                        )
                                                    )
                                                ),
                                                'ev-$model:value': function($v) {
                                                    $this.set(`${model}CheckedKeys`, $v);
                                                },
                                                'value': $this.get(`${model}CheckedKeys`)
                                            })
                                        ), 2 /* HasVNodeChildren */, _$cn({[`${k}-transfer-item`]: true, [`${k}-disabled`]: $value.disabled}), null, $value[keyName] || $value[labelName] || $value);
                                    }, $this)
                                )
                            })
                        );
                    }),
                    (__$blocks['list'] = function($super, data) {
                        var block = $blocks['list'];
                        var callBlock = function() {
                            return _$blocks['list'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['list'](_$no, model)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-transfer-list`))
        ], 0 /* UnknownChildren */, _$cn(`${k}-transfer-panel`))
    };
    
    return _$cv('div', {
        'className': _$cn(classNameObj),
        ...getRestProps(this)
    }, [
        Panel('left'),
        _$ce(2, 'div', [
            _$cc(Button, {
                'circle': true,
                'icon': true,
                'disabled': !enableRemove(),
                'ev-click': remove,
                'type': 'secondary',
                'children': (
                    _$cc(Icon, {
                        'className': _$cn(`${k}-transfer-icon ${k}-icon-left`)
                    })
                )
            }),
            _$cc(Button, {
                'circle': true,
                'icon': true,
                'disabled': !enableAdd(),
                'ev-click': add,
                'type': 'secondary',
                'children': (
                    _$cc(Icon, {
                        'className': _$cn(`${k}-transfer-icon ${k}-icon-right`)
                    })
                )
            })
        ], 4 /* HasNonKeyedChildren */, _$cn(`${k}-transfer-arrows`)),
        Panel('right')
    ]);
};