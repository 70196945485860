import {
    createVNode as _$cv,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {TransitionGroup} from 'intact';
import {getClassAndStyleForFixed} from './useFixedColumns';
import {Checkbox} from '../checkbox';
import {Radio} from '../radio';
import {TableCell} from './cell';
import {createElementVNode, className as cx, createFragment} from 'intact';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const {
        cols, data, checkType, hasFixedLeft,
        checked, disabled, className, merge,
        grid, index: rowIndex, indeterminate,
        allDisabled, selected, /* hidden,  */spreaded,
        hasChildren, indent, key, offsetMap,
        draggable, draggingKey, animation, loaded,
        spreadArrowIndex
    } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-disabled`]: disabled,
        [`${k}-checked`]: checked,
        [`${k}-selected`]: selected,
        /* [`${k}-hidden`]: hidden, */
        [`${k}-spreaded`]: spreaded,
        [`${k}-dragging`]: draggingKey === key,
        [className]: className,
    };
    
    const getGridItem = (columnIndex) => {
        return grid[rowIndex]?.[columnIndex] || {render: true, spans: null};
    };
    
    const vNodes = [];
    
    const pushCheckTypeVNode = (children) => {
        const {render, spans} = getGridItem(0);
        if (!render) return;
    
        vNodes.push(
            _$cv('td', {
                'key': '$checktype',
                ...getClassAndStyleForFixed({
                    fixed: hasFixedLeft ? 'left' : false,
                    className: [`${k}-table-check`],
                }, 0, k),
                ...spans
            }, children)
        )
    };
    
    if (checkType === 'checkbox') {
        pushCheckTypeVNode(
            _$cc(Checkbox, {
                'value': indeterminate ? false : checked,
                'disabled': allDisabled,
                'indeterminate': indeterminate,
                'ev-$change:value': this.onChangeChecked
            })
        );
    } else if (checkType === 'radio') {
        pushCheckTypeVNode(
            _$cc(Radio, {
                'value': indeterminate ? false : checked,
                'disabled': allDisabled,
                'ev-$change:value': this.onChangeChecked
            })
        );
    }
    
    const columnIndexOffset = checkType !== 'none' ? 1 : 0;
    cols.forEach((props, columnIndex) => {
        const {render, spans} = getGridItem(columnIndex + columnIndexOffset);
        if (!render) return;
    
        const columnKey = props.key;
        vNodes.push(
            _$cc(TableCell, {
                'props': props,
                'columnIndex': columnIndex,
                'rowIndex': rowIndex,
                'data': data,
                'loaded': loaded,
                'offset': offsetMap[columnKey],
                'checkType': checkType,
                'indent': indent,
                'grid': grid,
                'colspan': spans ? spans.colspan : undefined,
                'rowspan': spans ? spans.rowspan : undefined,
                'onClickArrow': this.onClickArrow,
                'hasChildren': hasChildren,
                'key': columnKey,
                'spreadArrowIndex': spreadArrowIndex
            }, columnKey)
        );
    });
    
    const rows = animation ?
        _$cc(TransitionGroup, {
            'name': 'k-fade-expand',
            'move': false,
            'children': vNodes
        }) :
        createFragment(vNodes, 8);
    /* const rows = vNodes; */
    
    // for performance, no normalize
    return createElementVNode(2, 'tr', rows, 2, cx(classNameObj), {
        'data-key': key,
        'ev-mouseenter': this.onMouseEnter,
        'ev-mouseleave': this.onMouseLeave,
        'ev-click': this.onClick,
        'ev-dragstart': draggable ? this.onRowDragStart : null,
        'ev-dragover': draggable ? this.onRowDragOver : null,
        'ev-dragend': draggable ? this.onRowDragEnd : null,
        'draggable': draggable ? true : null,
    });
};