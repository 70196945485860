import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Tooltip} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Tooltip, {
            'content': 'hello',
            'always': true,
            'value': true,
            'container': this.container,
            'children': '\n        always show this tootip \n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'content': 'hide',
            'always': true,
            'value': false,
            'container': this.container,
            'children': '\n        always hide this tootip \n    '
        }),
        _$ce(2, 'br'),
        _$ce(2, 'br'),
        _$cc(Tooltip, {
            'content': '',
            'always': true,
            'value': true,
            'children': '\n        don\'t show if content is empty\n    '
        })
    ], 4 /* HasNonKeyedChildren */, null, {
        'style': {position: 'relative'}
    });
};