import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createTextVNode as _$ct,
} from 'intact';
import {Table, TableColumn} from 'kpc';
var _$tmp0 = {
    'key': 'name',
    'title': 'Name'
};
var _$tmp1 = {
    'key': 'name',
    'title': 'Name'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Table, {
            'data': this.get('data'),
            'rowKey': data => data.name,
            'children': [
                _$cc(TableColumn, _$tmp0, 'name'),
                _$cc(TableColumn, {
                    'key': 'size',
                    'title': 'Size',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return [
                                        data.size,
                                        _$ct('MB\n            ')
                                    ];
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'size')
            ]
        }),
        _$ce(2, 'h4', '自定义展开Icon位置', 16 /* HasTextChildren */),
        _$cc(Table, {
            'data': this.get('data'),
            'rowKey': data => data.name,
            'spreadArrowIndex': 1,
            'children': [
                _$cc(TableColumn, _$tmp1, 'name'),
                _$cc(TableColumn, {
                    'key': 'size',
                    'title': 'Size',
                    '$blocks': function($blocks) {
                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                        return (
                            (
                                (_$blocks['template'] = function($super, [data]) {
                                    return [
                                        data.size,
                                        _$ct('MB\n            ')
                                    ];
                                }),
                                (__$blocks['template'] = function($super, data) {
                                    var block = $blocks['template'];
                                    var callBlock = function() {
                                        return _$blocks['template'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                })
                            ),
                            __$blocks
                        );
                    }.call($this, _$em)
                }, 'size')
            ]
        })
    ], 4 /* HasNonKeyedChildren */);
};